var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{attrs:{"lg":"12","sm":"12"}},[_c('b-overlay',{attrs:{"show":_vm.loading}},[_c('b-row',[_c('b-col',{attrs:{"lg":"12","sm":"12"}},[_c('CCard',[_c('CCardHeader',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('strong',[_vm._v("Send Notification")])])])]),_c('CCardBody',[_c('div',[_c('b-col',{attrs:{"lg":"12","sm":"12"}},[_c('b-overlay',{attrs:{"show":false}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.register)},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"name":"Send Title","vid":"title","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Send Title "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"title","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.title),callback:function ($$v) {_vm.$set(_vm.formData, "title", $$v)},expression:"formData.title"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"name":"Url","vid":"url","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Url "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"url","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.url),callback:function ($$v) {_vm.$set(_vm.formData, "url", $$v)},expression:"formData.url"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"name":"Description","vid":"description","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Description "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-textarea',{attrs:{"rows":"7","type":"text","id":"description","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.description),callback:function ($$v) {_vm.$set(_vm.formData, "description", $$v)},expression:"formData.description"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-12 text-right"},[_c('b-button',{staticClass:"mr-2",staticStyle:{"margin-top":"40px"},attrs:{"type":"submit","variant":"primary"}},[_vm._v(_vm._s(_vm.saveBtnName))])],1)])])]}}])})],1)],1)],1)])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }